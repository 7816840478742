/* app/frontend/lib/search_suggestions.js */
import { debounce } from 'lodash';

export default class SearchSuggestions {
    constructor(searchBarTarget, searchSuggestionsTarget, populateCallback) {
        this.searchBarTarget = searchBarTarget;
        this.searchSuggestionsTarget = searchSuggestionsTarget;
        this.populateCallback = populateCallback;
        this.fetchSearchSuggestions = debounce(this.fetchSearchSuggestions.bind(this), 300);
    }

    fetchSearchSuggestions(query) {
        const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        fetch(`/recipes/search_suggestions?query=${query}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': csrfToken
            },
            credentials: 'same-origin'
        })
            .then(response => response.json())
            .then(data => {
                this.populateCallback(data);
            });
    }

    populateSearchSuggestions(suggestions) {
        this.searchSuggestionsTarget.innerHTML = '';
        suggestions.forEach(suggestion => {
            const li = document.createElement('li');
            li.textContent = suggestion;
            li.addEventListener('click', () => {
                this.searchInputTarget.value = suggestion;
                const form = this.searchInputTarget.closest('form');
                if (form) {
                    form.submit();
                } else {
                    console.error('Form is null');
                }
            });
            this.searchSuggestionsTarget.appendChild(li);
        });
    }

    styleSearchElements() {
        const searchBar = this.searchBarTarget;
        const searchBarInput = searchBar.querySelector('input');
        const searchForm = searchBar.querySelector('form');

        this.searchBarTarget.style.padding = '10px';

        let debounceTimer;
        if (searchBarInput) {
            searchBarInput.addEventListener('input', () => {
                clearTimeout(debounceTimer);
                debounceTimer = setTimeout(() => {
                    const query = searchBarInput.value;
                    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
                    this.fetchSearchSuggestions(query);
                }, 300);
            });
        } else {
            console.error('searchBarInput is null');
        }
    }
}