import { Controller } from "stimulus"
import { gsap } from "gsap"

export default class extends Controller {
    static targets = ["navItem", "navContainer"];

    connect() {
        this.navContainerTarget.style.position = 'fixed';
        this.navContainerTarget.style.bottom = '0';
        this.navContainerTarget.style.width = '100%';
        this.navContainerTarget.style.zIndex = '1000';
        this.navContainerTarget.style.backgroundColor = '#ffffff';
        this.navContainerTarget.style.boxShadow = '0 -1px 0 rgba(0, 0, 0, 0.1)';
        this.navContainerTarget.style.padding = '10px 0';

        this.navItemTargets.forEach(item => {
            item.style.listStyle = 'none';
            item.style.display = 'inline-block';
            item.style.marginRight = '20px';
        });

        let lastScrollTop = 0;
        let safetyGap = 50;

        window.addEventListener('scroll', () => {
            let st = window.pageYOffset || document.documentElement.scrollTop;
            if (st > lastScrollTop + safetyGap){
                if (st > 340) {
                    gsap.killTweensOf(this.navContainerTarget);
                    gsap.to(this.navContainerTarget, { y: '100%', duration: 1.0 });
                }
                lastScrollTop = st;
            } else if (st < lastScrollTop - safetyGap) {
                gsap.killTweensOf(this.navContainerTarget);
                gsap.to(this.navContainerTarget, { y: '0', duration: 1.0 });
                lastScrollTop = st;
            }
        }, false);

        // Check viewport width and adjust display of navContainerTarget
        const mediaQuery = window.matchMedia('(min-width: 760px)');
        const checkDisplay = () => {
            if (mediaQuery.matches) {
                // If viewport is wider than 760px, hide navContainerTarget
                this.navContainerTarget.style.display = 'none';
            } else {
                // If viewport is narrower than 760px, show navContainerTarget
                this.navContainerTarget.style.display = 'block';
            }
        };
        // Initial check
        checkDisplay();
        // Listen for changes in viewport width
        mediaQuery.addListener(checkDisplay);
    }
}