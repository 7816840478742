// To see this message, add the following to the `<head>` section in your
// views/layouts/application.html.erb
//
//    <%= vite_client_tag %>
//    <%= vite_javascript_tag 'application' %>
console.log('Vite ⚡️ Rails')

// If using a TypeScript entrypoint file:
//     <%= vite_typescript_tag 'application' %>
//
// If you want to use .jsx or .tsx, add the extension:
//     <%= vite_javascript_tag 'application.jsx' %>

console.log('Visit the guide for more information: ', 'https://vite-ruby.netlify.app/guide/rails')

import '../../assets/stylesheets/application.scss';

import * as Turbo from "@hotwired/turbo"
Turbo.start()

import * as ActiveStorage from "@rails/activestorage"
ActiveStorage.start()

import { Application } from "stimulus"
import { definitionsFromContext } from "stimulus/webpack-helpers"
import StimulusReflex from 'stimulus_reflex'


import AnimationController from "../controllers/animation_controller";
import IslandController from "../controllers/island_controller";
import VisibilityController from "../controllers/visibility_controller";
import NavigatorController from "../controllers/navigator_controller";
import HeaderController from "../controllers/header_controller";

const application = Application.start();
application.register("animation", AnimationController);
application.register("island", IslandController);
application.register("visibility", VisibilityController);
application.register("navigator", NavigatorController);
application.register("header", HeaderController);

// Connects all Reflexes to StimulusReflex
StimulusReflex.initialize(application, { debug: true })


// Example: Load Rails libraries in Vite.
//
// import * as Turbo from '@hotwired/turbo'
// Turbo.start()
//
// import ActiveStorage from '@rails/activestorage'
// ActiveStorage.start()
//
// // Import all channels.
// const channels = import.meta.globEager('./**/*_channel.js')

// Example: Import a stylesheet in app/frontend/index.css
// import '~/index.css'
