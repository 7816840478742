import { Controller } from "stimulus"
import { gsap } from "gsap"
import SearchSuggestions from '../lib/search_suggestions.js'

export default class extends Controller {
    static targets = ["headerContainer", "searchButton", "searchInput", "overlay", "searchSuggestions"];

    connect() {
        this.styleHeaderContainer();
        this.styleSearchButton();
        this.styleSearchInput();
        this.setupScrollEvent();
        this.setupSearchButtonEvent();
        this.setupSearchInputEvent();
        this.setupOverlayEvent();
        this.setupDocumentClickEvent();
        this.setupSearchSuggestions();

        // Add a flag to track whether the search input is open or not
        this.isSearchInputOpen = false;

        // Get the current path
        const path = window.location.pathname;

        // Show or hide the search button based on the current path
        if (path === '/' || path.includes('/recipes')) {
            this.searchButtonTarget.style.display = 'block';
        } else {
            this.searchButtonTarget.style.display = 'none';
        }
    }

    setupScrollEvent() {
        let lastScrollTop = 0;
        window.addEventListener('scroll', () => {
            let st = window.pageYOffset || document.documentElement.scrollTop;
            if (st > lastScrollTop){
                gsap.killTweensOf(this.headerContainerTarget);
                gsap.to(this.headerContainerTarget, { y: '-100%', duration: 1.0 });
            } else {
                gsap.killTweensOf(this.headerContainerTarget);
                gsap.to(this.headerContainerTarget, { y: '0', duration: 1.0 });
            }
            lastScrollTop = st <= 0 ? 0 : st;
        }, false);
    }

    setupSearchButtonEvent() {
        this.searchButtonTarget.addEventListener('click', (event) => {
            event.stopPropagation();
            if (this.searchInputTarget.value.trim() !== '') {
                this.searchInputTarget.form.submit();
            } else {
                if (!this.isSearchInputOpen) {
                    this.searchInputTarget.style.display = 'block';
                    this.overlayTarget.style.display = 'block';
                    this.searchSuggestionsTarget.style.display = 'block';
                    this.searchInputTarget.focus();
                    this.isSearchInputOpen = true;
                } else {
                    this.searchInputTarget.style.display = 'none';
                    this.overlayTarget.style.display = 'none';
                    this.searchSuggestionsTarget.style.display = 'none';
                    this.isSearchInputOpen = false;
                }
            }
        });
    }

    setupSearchInputEvent() {
        this.searchInputTarget.addEventListener('click', (event) => {
            event.stopPropagation();
        });
    }

    setupOverlayEvent() {
        this.overlayTarget.addEventListener('click', () => {
            this.searchInputTarget.style.display = 'none';
            this.overlayTarget.style.display = 'none';
            this.searchSuggestionsTarget.style.display = 'none';
            this.isSearchInputOpen = false;
        });
    }

    setupDocumentClickEvent() {
        document.addEventListener('click', () => {
            this.searchInputTarget.style.display = 'none';
            this.overlayTarget.style.display = 'none';
            this.searchSuggestionsTarget.style.display = 'none';
            this.isSearchInputOpen = false;
        });
    }

    setupSearchSuggestions() {
        this.searchSuggestions = new SearchSuggestions(
            this.searchInputTarget,
            this.searchSuggestionsTarget,
            this.populateSearchSuggestions.bind(this)
        );

        this.searchSuggestions.styleSearchElements();

        this.searchInputTarget.addEventListener('input', (event) => {
            this.searchSuggestions.fetchSearchSuggestions(event.target.value);
        });
    }

    populateSearchSuggestions(suggestions) {
        this.searchSuggestionsTarget.innerHTML = '';
        suggestions.forEach(suggestion => {
            const li = document.createElement('li');
            li.textContent = suggestion;
            li.addEventListener('click', () => {
                this.searchInputTarget.value = suggestion;
                this.searchInputTarget.form.submit();
            });
            this.searchSuggestionsTarget.appendChild(li);
        });

        this.styleSearchSuggestions();
    }

    styleSearchSuggestions() {
        this.searchSuggestionsTarget.style.backgroundColor = '#ffffff';
        this.searchSuggestionsTarget.style.border = '1px solid #cccccc';
        this.searchSuggestionsTarget.style.borderRadius = '4px';
        this.searchSuggestionsTarget.style.padding = '10px';
        this.searchSuggestionsTarget.style.maxHeight = '200px';
        this.searchSuggestionsTarget.style.overflowY = 'auto';
        this.searchSuggestionsTarget.style.position = 'absolute';
        this.searchSuggestionsTarget.style.width = '100%';
        this.searchSuggestionsTarget.style.boxShadow = '0px 8px 16px 0px rgba(0,0,0,0.2)';
        this.searchSuggestionsTarget.style.zIndex = '10000';
        this.searchSuggestionsTarget.style.listStyle = 'none';

        // Style each search suggestion
        const suggestions = this.searchSuggestionsTarget.querySelectorAll('li');
        suggestions.forEach(suggestion => {
            suggestion.style.backgroundColor = '#ffffff';
            suggestion.style.padding = '10px';
            suggestion.style.cursor = 'pointer';

            // Change background color on hover
            suggestion.addEventListener('mouseover', () => {
                suggestion.style.backgroundColor = '#f1f1f1';
            });

            // Reset background color on mouseout
            suggestion.addEventListener('mouseout', () => {
                suggestion.style.backgroundColor = '#ffffff';
            });
        });
    }

    styleHeaderContainer() {
        this.headerContainerTarget.style.position = 'fixed';
        this.headerContainerTarget.style.top = '0';
        this.headerContainerTarget.style.width = '100%';
        this.headerContainerTarget.style.zIndex = '1000';
        this.headerContainerTarget.style.backgroundColor = '#ffffff';
        this.headerContainerTarget.style.boxShadow = '0 1px 0 rgba(0, 0, 0, 0.1)';
        this.headerContainerTarget.style.padding = '10px 0';
    }

    styleSearchButton() {
        this.searchButtonTarget.style.backgroundColor = '#2d9f96'; // color-500
        this.searchButtonTarget.style.border = '2px solid #2d9f96';
        this.searchButtonTarget.style.color = '#fff';
        this.searchButtonTarget.style.padding = '10px 20px';
        this.searchButtonTarget.style.textTransform = 'uppercase';
        this.searchButtonTarget.style.cursor = 'pointer';
        this.searchButtonTarget.style.borderRadius = '4px';
    }

    styleSearchInput() {
        this.searchInputTarget.style.padding = '10px';
        this.searchInputTarget.style.border = '2px solid #2d9f96'; // color-500
        this.searchInputTarget.style.borderRadius = '4px';
        this.searchInputTarget.style.outline = 'none';
        this.searchInputTarget.style.width = '200px';
        this.searchInputTarget.style.fontSize = '16px'; // Add this line
        this.searchInputTarget.style.caretColor = 'transparent'; // Disable blinking cursor on mobile
    }
}