import { Controller } from "stimulus";
import { gsap } from 'gsap';
import debounce from 'lodash.debounce';

export default class extends Controller {
    static targets = ["island", "searchBar", "searchButton", "searchInput", "searchSuggestions" ];

    connect() {
        this.initialStyles();  // Ensure initial styles are applied on connect
        this.setupEventListeners();
        this.checkURLAndApplyStyles();
    }


    setupEventListeners() {
        const debouncedToggle = debounce(() => this.toggleIsland(), 300, { leading: true, trailing: false });
        this.islandTarget.addEventListener('click', debouncedToggle);
        this.islandTarget.addEventListener('mouseover', () => this.hoverIsland());
        this.islandTarget.addEventListener('mouseout', () => this.unhoverIsland());
        this.searchInputTarget.addEventListener('input', debounce(() => this.fetchSearchSuggestions(), 300));
        document.addEventListener('click', (event) => {
            if (!this.islandTarget.contains(event.target) && !this.searchBarTarget.contains(event.target) && parseFloat(this.searchBarTarget.style.width) > 100) {
                debouncedToggle();
            }
        });
    }


    applyStylesToElement(element) {
        element.style.cssText = `
            display: flex;
            position: relative;
            align-items: center;
            gap: 4px;
            padding: 10px;
            color: #495057;
            text-decoration: none;
            border-radius: 5px;
            z-index: -12;
            background-color: #f3f3f4;
            cursor: pointer;
            opacity: 0;
        `;
    }

    initialStyles() {
        this.islandTarget.style.cssText = `
            width: 240px;
            height: 56px;
            background-color: #2d9f96;
            border-radius: 22px;
            position: fixed;
            color: white;
            top: 168px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 1000;
            display: flex;
            gap: 10px;
            padding: 2px 32px;
            justify-content: center;
            align-items: center;
            transition: all 0.2s ease;
            cursor: pointer;
        `;

        this.searchBarTarget.style.cssText = `
            width: 0;
            height: 0;
            visibility: hidden;
            border-radius: 50%;
            position: fixed;
            top: 180px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 999;
            transition: width 0.3s ease, height 0.3s ease, visibility 0s 0.3s;  // Delay visibility to after transition
        `;

        this.searchInputTarget.style.cssText = `
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 80%;
            padding: 10px;
            border: none;
            border-radius: 5px;
            font-size: 16px;
            background-color: #f3f3f4;
            color: #1d5251;
            opacity: 0;  // Start fully transparent
            transition: opacity 0.3s ease;
        `;

        this.searchButtonTarget.style.cssText = `
            opacity: 0;  // Start fully transparent
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            transition: opacity 0.3s ease;
        `;
    }

    hoverIsland() {
        this.animateIsland(1.04);
    }

    unhoverIsland() {
        this.animateIsland(1);
    }

    animateIsland(scale) {
        gsap.to(this.islandTarget, { duration: 0.12, scale: scale, ease: "power4.inOut" });
    }

    toggleIsland() {
        const isOpen = parseFloat(this.searchBarTarget.style.width) > 100;
        if (!isOpen) {
            this.expandIsland();
            this.expandSearchBar();
        } else {
            this.contractIsland();
            this.contractSearchBar();
        }
    }

    expandIsland() {
        gsap.to(this.islandTarget, {
            width: '350px', height: '80px', duration: 0.3, ease: "power2.out",
            onComplete: () => gsap.fromTo(this.searchBarTarget, { opacity: 0 }, { opacity: 1, duration: 0.3 })
        });
        this.expandSearchBar();
    }

    contractIsland() {
        gsap.to(this.islandTarget, {
            width: '240px', height: '56px', duration: 0.3, ease: "power2.in",
            onComplete: () => {
                this.islandTarget.style.opacity = 1;  // Ensure full opacity after transition
            }
        });
        this.contractSearchBar();
    }

    expandSearchBar() {
        gsap.to(this.searchBarTarget, {
            width: '360px', height: '100px', padding: '10px', borderRadius: '10px',
            duration: 0.3, ease: "power2.out",
            onComplete: () => {
                gsap.to(this.searchInputTarget, { opacity: 1, duration: 0.3 });
                this.searchInputTarget.focus();  // Focus after expanding
            }
        });
    }

    contractSearchBar() {
        gsap.to(this.searchBarTarget, {
            width: 0, height: 0, padding: 0, borderRadius: '50%',
            duration: 0.3, ease: "power2.in",
            onComplete: () => {
                this.searchBarTarget.style.visibility = 'hidden';  // Hide after contracting
            }
        });
    }

    openKeyboard() {
        // Check if the device is a mobile device
        const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

        if (isMobile) {
            // Open the keyboard by focusing on the search input
            this.searchInputTarget.focus();
        }
    }


    liquidExpand(state) {
        const target = state === 'open' ? this.searchBarTarget : this.islandTarget;
        gsap.fromTo([this.islandTarget, target], { filter: 'blur(0px)' }, { filter: 'blur(2.4px)', duration: 0.3, yoyo: true, repeat: 1, ease: "power1.inOut" });
    }

    liquidContract(state) {
        const target = state === 'close' ? this.searchBarTarget : this.islandTarget;
        gsap.fromTo([this.islandTarget, target], { filter: 'blur(1.4px)' }, { filter: 'blur(0px)', duration: 0.3, yoyo: true, repeat: 1, ease: "power1.inOut" });
        gsap.to(this.searchButtonTarget, { filter: 'blur(0px)', duration: 0.3, ease: "power1.inOut" });
    }

    checkURLAndApplyStyles() {
        const currentURL = window.location.pathname;
        const queryParameters = window.location.search;
        const recipeRegex = /^\/recipes\b(?:\/|$)/;
        const rootRegex = /^\/$/;
        const rootOrRecipesPage = rootRegex.test(currentURL) || recipeRegex.test(currentURL);
        if (rootOrRecipesPage || queryParameters.startsWith('?page=')) {
            this.applyCustomStyles();
        } else {
            this.backButtonStyles();
        }
    }

    applyCustomStyles() {
        this.initialStyles();
        this.searchInputTarget.style.cssText = `
            all: unset;
            display: block;
            width: 100%;
            padding: 18px 22px;
            font-size: 16px;
            color: #333;
            background: #FFF;
            border: 1px solid #CCC;
            border-radius: 12px;
            box-shadow: 0 2px 6px rgba(0,0,0,0.1);
        `;
        this.searchButtonTarget.style.cssText = `
            all: unset;
            display: block;
            width: auto;
            padding: 10px 20px;
            background-color: #2d9f96;
            color: white;
            border: none;
            border-radius: 20px;
            cursor: pointer;
        `;
        this.islandTarget.style.cssText = `
            width: 220px;
            height: 56px;
            background-color: #2d9f96;
            border-radius: 22px;
            position: fixed;
            color: white;
            top: 168px;
            left: 50%;
            transform: translateX(-50%);
            z-index: 1000;
            display: flex;
            gap: 10px;
            padding: 2px 32px;
            justify-content: center;
            align-items: center;
            transition: all 0.2s ease;
            cursor: pointer;
        `;
    }

    backButtonStyles() {
        // Add your styles for the back button here
    }

    fetchSearchSuggestions() {
        const query = this.searchInputTarget.value;
        const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        this.isFetching = true;
        fetch(`/recipes/search_suggestions?query=${query}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': csrfToken
            },
            credentials: 'same-origin'
        })
            .then(response => response.json())
            .then(data => {
                setTimeout(() => {
                    this.populateSearchSuggestions(data);
                }, 300);
            });
    }

    populateSearchSuggestions(suggestions) {
        this.searchSuggestionsTarget.innerHTML = '';
        const formElement = this.searchBarTarget.querySelector('form');
        let elements = [];
        suggestions.forEach(suggestion => {
            const li = document.createElement('li');
            li.textContent = suggestion;
            this.applyStylesToSearchSuggestion(li);
            li.addEventListener('mouseover', () => {
                li.style.backgroundColor = '#d3f4ee';
            });
            li.addEventListener('mouseout', () => {
                li.style.backgroundColor = '#f3f3f4';
            });
            li.addEventListener('click', () => {
                this.searchInputTarget.value = suggestion;
                formElement.submit();
            });
            this.searchSuggestionsTarget.appendChild(li);
            elements.push(li);
        });
        this.searchSuggestionsTarget.style.width = '100%';
        this.searchSuggestionsTarget.style.height = '100%';
        if (this.isFetching) {
            gsap.to(elements, {
                opacity: 1,
                x: 0,
                stagger: 0.1,
                ease: "power1.out",
                duration: 0.3
            });
        } else {
            gsap.to(elements, {
                opacity: 1,
                y: 0,
                stagger: 0.1,
                ease: "power1.out",
                duration: 0.3
            });
        }
        this.isFetching = false;
    }

    applyStylesToSearchSuggestion(li) {
        li.style.cssText = `
            padding: 16px;
            background-color: #f3f3f4;
            color: #1d5251;
            border: 1px solid #d3f4ee;
            border-radius: 5px;
            margin-bottom: 5px;R
            cursor: pointer;
            list-style-type: none;
            opacity: 0;
            transform: translateY(-20px);
        `;
    }
}
