/* app/frontend/controllers/visibility_controller.js */
import { Controller } from "stimulus"
import { gsap } from 'gsap';

export default class extends Controller {
    static targets = ["ingredientBenefits"];

    toggle(event) {
        const fullDescription = event.currentTarget.querySelector('.full-description');
        fullDescription.classList.toggle('d-none');

        if (!fullDescription.classList.contains('d-none')) {
            gsap.fromTo(fullDescription, { height: 0 }, { height: 'auto', duration: 0.3 });
        } else {
            gsap.to(fullDescription, { height: 0, duration: 0.3 });
        }
    }
}