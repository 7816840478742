/* app/frontend/controllers/animation_controller.js */
import { Controller } from "stimulus"
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

export default class extends Controller {
    static targets = ["header", "searchBar", "searchSuggestions"];

    connect() {
        this.initializeAnimations();
    }

    hideHeaderOnScroll() {
        // Ensure the header height is calculated in pixels for consistent behavior
        const headerHeight = this.headerTarget.clientHeight;  // Get the height in pixels

        gsap.to(this.headerTarget, {
            y: () => `-${headerHeight}px`,  // Use a function to dynamically assign pixel value
            ease: "none",
            scrollTrigger: {
                trigger: this.headerTarget,
                start: "top+=560",
                end: () => `+=${headerHeight}`,  // End when the header should completely disappear
                scrub: 1,  // Use scrubbing with a smoother transition
            },
        });
    }

    initializeAnimations() {
        this.hideHeaderOnScroll();
        this.animateSearchBar();
        this.setupResponsiveBehaviors();
    }

    setupResponsiveBehaviors() {
        // Responsive animations for width under 768px
        if (window.innerWidth < 768) {
            this.animateSearchBarForSmallScreens();
            this.styleSearchElementsForSmallScreens();
        } else {
            this.moveSearchButtonToRight();
            this.animateSearchBar();
            this.styleSearchElements();
        }
    }

    animateSearchBarForSmallScreens() {
        const searchBar = this.searchBarTarget;
        searchBar.addEventListener('focus', () => {
            gsap.to(searchBar, {
                scale: 1.1,
                duration: 0.3,
                ease: 'power1.inOut'
            });
        });

        searchBar.addEventListener('blur', () => {
            gsap.to(searchBar, {
                scale: 1,
                duration: 0.3,
                ease: 'power1.inOut'
            });
        });
    }

    styleSearchElementsForSmallScreens() {
        const searchBarInput = this.searchBarTarget.querySelector('input');
        const searchSuggestions = this.searchSuggestionsTarget;

        searchBarInput.addEventListener('input', () => {
            clearTimeout(this.debounceTimer);
            this.debounceTimer = setTimeout(() => {
                this.fetchSearchSuggestions(searchBarInput.value);
            }, 300);
        });

        searchBarInput.addEventListener('focus', () => {
            gsap.to(searchSuggestions, {
                autoAlpha: 1,
                y: 0,
                duration: 0.3
            });
        });

        searchBarInput.addEventListener('blur', () => {
            gsap.to(searchSuggestions, {
                autoAlpha: 0,
                y: 20,
                duration: 0.3
            });
        });
    }

    fetchSearchSuggestions(query) {
        const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
        const searchSuggestions = this.searchSuggestionsTarget;
        fetch(`/recipes/search_suggestions?query=${query}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': csrfToken
            },
            credentials: 'same-origin'
        })
            .then(response => response.json())
            .then(data => {
                this.populateSearchSuggestions(data);
            });
    }

    populateSearchSuggestions(suggestions) {
        const searchSuggestions = this.searchSuggestionsTarget;
        searchSuggestions.innerHTML = '';
        suggestions.forEach(suggestion => {
            const li = document.createElement('li');
            li.textContent = suggestion;
            li.addEventListener('click', () => {
                this.searchBarTarget.querySelector('input').value = suggestion;
                this.searchBarTarget.querySelector('form').submit();
            });
            searchSuggestions.appendChild(li);
        });
    }


    styleSearchElements() {
        const searchBar = this.searchBarTarget;
        const searchButton = searchBar.querySelector('.search-button');
        const searchBarInput = searchBar.querySelector('input');
        const searchForm = searchBar.querySelector('form');
        const headerCenter = document.querySelector('.header-center'); // get the center island

        this.searchBarTarget.style.padding = '10px';

        let debounceTimer;
        searchBarInput.addEventListener('input', () => {
            clearTimeout(debounceTimer); // clear the timer if the input event is fired again before the timer is up
            debounceTimer = setTimeout(() => { // start a new timer
                const query = searchBarInput.value;
                const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
                fetch(`/recipes/search_suggestions?query=${query}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-CSRF-Token': csrfToken
                    },
                    credentials: 'same-origin'
                })

                    .then(response => response.json())
                    .then(data => {
                        const searchSuggestions = this.searchSuggestionsTarget;
                        searchSuggestions.innerHTML = ''; // Clear the current search suggestions

                        searchSuggestions.style.position = 'absolute';
                        searchSuggestions.style.width = `${headerCenter.offsetWidth}px`; // make the suggestions the same width as the center island
                        searchSuggestions.style.top = `${searchBar.offsetHeight - 12}px`; // position the suggestions just below the search bar and 8px higher
                        searchSuggestions.style.backgroundColor = '#f0f0f0'; // pastel color
                        searchSuggestions.style.opacity = '0';
                        searchSuggestions.style.transform = 'translateY(20px)'; // start 20px below the original position
                        searchSuggestions.style.transition = 'all 0.3s ease'; // animate the transition
                        searchSuggestions.style.borderRadius = '5px'; // round the corners
                        searchSuggestions.style.padding = '10px'; // add some space around the suggestions
                        searchSuggestions.style.marginTop = '10px'; // add some space between the search bar and the suggestions
                        searchSuggestions.style.boxShadow = '0px 8px 16px 0px rgba(0,0,0,0.2)'; // add a shadow effect
                        searchSuggestions.style.listStyleType = 'none'; // remove the dot from the ul elements

                        data.forEach(suggestion => {
                            const li = document.createElement('li');
                            li.textContent = suggestion;
                            li.style.padding = '10px'; // add some space around the text
                            li.style.fontSize = '1rem'; // make the text larger
                            li.style.color = '#333'; // make the text darker
                            li.style.transition = 'background-color 0.3s ease'; // animate the background color change
                            li.addEventListener('click', () => {
                                searchBarInput.value = suggestion; // Set the input value to the clicked suggestion
                                searchForm.submit(); // Submit the search form
                            });

                            // Add hover event listener to the suggestion
                            li.addEventListener('mouseover', () => {
                                li.style.backgroundColor = '#e0e0e0'; // Change the background color when hovered'
                                li.style.cursor = 'pointer'; // Change the cursor to a pointer
                            });

                            // Add mouseout event listener to the suggestion
                            li.addEventListener('mouseout', () => {
                                li.style.backgroundColor = '#f0f0f0'; // Change the background color back when mouse is out
                            });

                            searchSuggestions.appendChild(li); // Add the new suggestion to the dropdown
                        });

                        if (data.length > 0) {
                            // Animate the search suggestions with GSAP
                            gsap.to(searchSuggestions, {
                                opacity: 1, // fade in
                                y: 0, // slide up
                                duration: 0.3,
                                ease: 'power1.out'
                            });
                        } else {
                            // Animate the search suggestions with GSAP
                            gsap.to(searchSuggestions, {
                                opacity: 0, // fade out
                                y: 20, // slide down
                                duration: 0.3,
                                ease: 'power1.out'
                            });
                        }
                    });
            }, 300);
        });

        // Style the search button
        searchButton.style.justifyContent = 'center';
        searchButton.style.alignItems = 'center';
        searchButton.style.backgroundColor = '#6fd3c7;';
        searchButton.style.borderRadius = '16px';
        searchButton.style.border = 'none';
        searchButton.style.padding = '18px 24px';
        searchButton.style.transition = 'background-color 0.3s ease';

        searchButton.addEventListener('mouseover', () => {
            searchButton.style.backgroundColor = '#47bab0';
        });

        searchButton.addEventListener('click', () => {
            searchButton.style.backgroundColor = '#2d9f96';
            searchForm.submit(); // Submit the form when the button is clicked
        });

        searchButton.addEventListener('mouseout', () => {
            searchButton.style.backgroundColor = '#6fd3c7';
        });

        // Style the search bar input
        searchBarInput.style.border = 'none';
        searchBarInput.style.backgroundColor = 'transparent';
        searchBarInput.style.border = '1px solid #c1fdf2';
        searchBarInput.style.padding = '0 1rem';
        searchBarInput.style.backgroundColor = '#a1ded4';
        searchBarInput.style.fontSize = '1rem';
        searchBarInput.style.boxShadow = '0 0 10px 1px rgba(0, 0, 0, 0.1)';
        searchBarInput.style.transition = 'all 0.3s ease';

        searchBarInput.style.borderRadius = '12px';
        searchBarInput.style.height = '3rem'; // Increase the height of the input field

        searchBarInput.addEventListener('focus', () => {
            searchBarInput.style.outline = 'none';
            searchBarInput.style.border = '1px solid #c1fdf2';
            searchBarInput.style.backgroundColor = '#9deedd';

            searchBarInput.style.borderRadius = '6px';
            searchBarInput.style.boxShadow = '0 0 10px 1px rgba(0, 0, 0, 0.13)';
        });

        searchBarInput.addEventListener('blur', () => {
            const searchSuggestions = this.searchSuggestionsTarget;
            gsap.to(searchSuggestions, {
                opacity: 0,
                y: 18,
                duration: 0.3,
                ease: 'power1.out'
            });
        });
    }

    moveSearchButtonToRight() {
        if (window.innerWidth > 786) {
            const searchBar = this.searchBarTarget;
            const searchButton = searchBar.querySelector('.search-button');

            // Move the search button to the right of the search bar
            searchBar.appendChild(searchButton);
        }
    }

    animateSearchBar() {
        if (window.innerWidth > 786) {
            let searchBarHeight = this.searchBarTarget.offsetHeight; // get the original height of the search bar
            let searchBarWidth = this.searchBarTarget.offsetWidth; // get the original width of the search bar
            const searchBarInput = this.searchBarTarget.querySelector('input'); // get the input field
            let searchBarInputWidth = searchBarInput.offsetWidth; // get the original width of the input field
            let shrinkTimeout; // define a variable to hold the timeout

            this.searchBarTarget.addEventListener('mouseenter', (event) => {
                if (event.target !== this.searchBarTarget) return; // ignore if the event target is not the search bar

                clearTimeout(shrinkTimeout); // clear the timeout if the mouse re-enters the search bar

                let tl = gsap.timeline(); // create a new timeline

                tl.to(this.searchBarTarget, {
                    height: searchBarHeight * 1.2 + "px", // expand the search bar to 120% of its original height
                    width: searchBarWidth * 1.2 + "px", // expand the search bar to 120% of its original width
                    duration: 0.38,
                    ease: "power1.out",
                    transformOrigin: "left bottom", // expand from the bottom
                    overwrite: "auto" // overwrite any existing animations
                })
                tl.to(searchBarInput, {
                    width: searchBarInputWidth * 1.2 + "px", // expand the input field to 120% of its original width
                    duration: 0.09,
                    ease: "power1.out",
                    overwrite: "auto" // overwrite any existing animations
                }, "-=0.38"); // start the input field animation at the same time as the search bar animation
            });

            this.searchBarTarget.addEventListener('mouseleave', (event) => {
                // Check if the related target is the search suggestions element
                if (this.searchBarTarget.contains(event.relatedTarget) || event.relatedTarget === this.searchSuggestionsTarget) return;
                if (this.searchBarTarget.contains(event.relatedTarget)) return; // ignore if the related target is a descendant of the search bar

                // Start a timeout to shrink the search bar after 0.68 seconds
                shrinkTimeout = setTimeout(() => {
                    let tl = gsap.timeline(); // create a new timeline

                    tl.to(this.searchBarTarget, {
                        height: searchBarHeight + "px", // return the search bar to its original height
                        width: searchBarWidth + "px", // return the search bar to its original width
                        duration: 0.38,
                        ease: "power1.out",
                        overwrite: "auto" // overwrite any existing animations
                    })
                    tl.to(searchBarInput, {
                        width: searchBarInputWidth + "px", // return the input field to its original width
                        duration: 0.09,
                        ease: "power1.out",
                        overwrite: "auto" // overwrite any existing animations
                    }, "-=0.38"); // start the input field animation at the same time as the search bar animation
                }, 1298);
            });

            this.searchBarTarget.addEventListener('focus', () => {
                gsap.to(this.searchBarTarget, {
                    height: searchBarHeight * 1.2 + "px", // expand the search bar to 120% of its original height
                    width: searchBarWidth * 1.2 + "px", // expand the search bar to 120% of its original width
                    duration: 0.38,
                    ease: "power1.out",
                    transformOrigin: "left bottom", // expand from the bottom
                    overwrite: "auto" // overwrite any existing animations
                });
            });

            this.searchBarTarget.addEventListener('blur', (event) => {
                if (this.searchBarTarget.contains(event.relatedTarget)) return; // ignore if the related target is a descendant of the search bar

                gsap.to(this.searchBarTarget, {
                    height: searchBarHeight + "px", // return the search bar to its original height
                    width: searchBarWidth + "px", // return the search bar to its original width
                    duration: 0.38,
                    ease: "power1.out",
                    overwrite: "auto" // overwrite any existing animations
                });
            });
        }
    }
}
